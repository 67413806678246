import React from "react"
import { Link, graphql } from "gatsby"
import Navigation from "../components/navigation"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Objetivo from "../images/SVG/objetivo.svg"
import Dream from "../images/iconos-08.png"
import Footer from "../components/footer"

const EjerciciosPage = props => (
  <Layout>
    <SEO title="Sobre Sueña en Colores" />
    <Navigation />

    <div className="w-full mx-auto top-illustration">
      <Img fluid={props.data.oneImage.childImageSharp.fluid} className="h-64" />
    </div>

    <div className="w-full mx-auto text-center bg-blue-200 pt-8 pb-8">
      <h1 className="text-3xl lg:text-4xl text-blue-900">Donaciones</h1>
      <div>
        <p className="w-10/12 lg:w-1/2 text-lg mt-3 mx-auto">
          Sueña en Colores depende de la ayuda de quienes queremos niños con
          salud mental saludable.
        </p>
      </div>
    </div>

    <div className="w-full mx-auto bg-green-100 pt-8 pb-8">
      <div className="content-item w-full lg:w-10/12 lg:flex mx-auto">
        <div className="w-full lg:w-1/4 text-center">
          <img
            src={Objetivo}
            alt="Mindfulness y Arteterapa"
            className="mx-auto w-1/2 lg:w-8/12"
          />
        </div>
        <div className="w-full lg:w-3/4">
          <h2 className="text-2xl my-3  px-5 text-blue-800">
            Donar via Paypal
          </h2>
          <p className="px-5">
            Puede donar fácilmente por medio de Paypal utilizando el siguiente
            link:
          </p>
          <p className="pt-4">
            <a
              href="https://www.paypal.com/paypalme/asccostarica?locale.x=es_XC"
              target="_blank"
              rel="nofollow"
              className="bg-yellow-500 rounded-lg px-5 py-3 inline-block mt-5 ml-3"
            >
              Donar via Paypal
            </a>
          </p>

          <h2 className="text-2xl my-3  px-5 text-blue-800 mt-10">
            Donar a nuestras cuentas bancarias
          </h2>
          <p className="px-5">
            Puede donar a Sueña en Colores por medio de transferencia bancaria.
            Cuentas BAC San José.
          </p>
          <h3 className="text-xl px-5 mt-10 text-blue-700">Cédula jurídica:</h3>
          <p className="cuentas">3-002-509765</p>
          <h3 className="text-xl px-5 mt-10 text-blue-700">
            Cuenta en colones:
          </h3>
          <p className="cuentas">Cuenta corriente: 907823090</p>
          <p className="cuentas">Cuenta cliente: 102 0000 9078230909</p>
          <p className="cuentas">IBAN: CR27010200009078230909</p>

          <h3 className="text-xl px-5 mt-10 text-blue-700">
            Cuenta en dólares:
          </h3>
          <p className="cuentas">Cuenta corriente: 908336993</p>
          <p className="cuentas">Cuenta cliente: 102 0000 9083369934</p>
          <p className="cuentas">IBAN: CR02010200009083369934</p>
        </div>
      </div>
    </div>

    <div className="w-full mx-auto text-center bg-gray-100 pt-8 pb-8 border border-gray-200">
      <h1 className="text-2xl lg:text-4xl text-blue-900">
        Uno hace la diferencia
      </h1>
      <div className="mt-5">
        <p className="w-2/3 lg:w-1/2 text-md mt-3 mx-auto">
          La ciencia ha demostrado algo que todos intuimos. Ayudar a otros nos
          hace personas más felices. Ahora sigue el paso más importante: hacelo
          hoy, ayudá hoy.
        </p>
      </div>
    </div>

    <Footer />
  </Layout>
)

export default EjerciciosPage

export const anaQuery = graphql`
  query {
    oneImage: file(relativePath: { eq: "meditate.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
